@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.custom-btn {
    @apply  py-2 px-3 uppercase text-xs font-bold cursor-pointer tracking-wider border-2 border-gray hover:bg-white hover:text-lightGray  transition ease-out duration-500;
}
