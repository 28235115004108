
  
  .title {
    user-select: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    text-align: center;
    margin: 42px auto 0;
  }
  
  .range-slider {
    margin: 24px auto 0;
    height: 100%;
  }
  
  .range-slider.margin-lg {
    margin: 42px auto 0;
  }
  
  @keyframes rotate-clockwise {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate-anti-clockwise {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  
  @keyframes move-bg {
    0% {
      background-position: 50% 0;
    }
  
    25% {
      background-position: 100% 0;
    }
  
    50% {
      background-position: 50% 0;
    }
  
    75% {
      background-position: 0% 0;
    }
  
    100% {
      background-position: 50% 0;
    }
  }

  .single-thumb{
    border-radius: 0px;
    background-color: #88807B;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }
  /* Vertical Range Slider */
  .single-thumb[data-vertical] {
    height: 80%;
    margin-top: 0%;
    margin-bottom: 0%;
  }
    
  /* Range Slider as a Single Thumb Slider */
  .single-thumb .range-slider__thumb[data-upper] {
    width: 0;
    height: 0;
  }
  .single-thumb .range-slider__thumb[data-lower] {
    width: 100px;
    height: 15px;
    border-radius: 0px;
    background-color: #5e5955;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .single-thumb .range-slider__range {
    border-radius: 0px;
    background-color: #88807B;
    
  }

  .single-thumb-horizontal{
    border-radius: 0px;
    background-color: #88807B;
    height: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* Vertical Range Slider */
  .single-thumb-horizontal[data-horizontal] {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  /* Range Slider as a Single Thumb Slider */
  .single-thumb-horizontal .range-slider__thumb[data-upper] {
    width: 0;
    height: 0;
  }
  .single-thumb-horizontal .range-slider__thumb[data-lower] {
    width: 15px;
    height: 60px;
    border-radius: 0px;
    background-color: #5e5955;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .single-thumb-horizontal .range-slider__range {
    border-radius: 0px;
    background-color: #88807B;
    
  }

  
.dropdownItems {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;

}

.dropdownItems.is-open {
  max-height: 100%;
}

.navbar {
  max-height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.navbar.is-open {
  max-height: 100%;
}

